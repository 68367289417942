<script setup lang="ts">
const groupAges = [
    {
        label: '0-12',
        value: '0to12'
    },
    {
        label: '13-17',
        value: '13to17'
    },
    {
        label: '18-21',
        value: '18to21'
    },
    {
        label: '22-35',
        value: '22to35'
    },
    {
        label: '36-49',
        value: '36to49'
    },
    {
        label: '50+',
        value: '50plus'
    }
]

const groupAgeRanges = defineModel({
    type: Array,
    default: () => []
})
</script>

<template>
    <div class="hz-age-ranges-select d-flex flex-column justify-content-center align-items-start">
        <label class="pre-title mb-2">Age Ranges</label>

        <ul class="list-unstyled w-100 mb-0">
            <li
                v-for="(item, index) in groupAges"
                :key="`groupAge-${ index }`"
                class="checkbox mb-2"
            >
                <div class="custom-control custom-checkbox custom-checkbox-2">
                    <label class="custom-control-label">
                        {{ item.label }}

                        <input
                            v-model="groupAgeRanges"
                            type="checkbox"
                            class="custom-control-input"
                            :value="item.value"
                        >
                        <span class="checkmark" />
                    </label>
                </div>
            </li>
        </ul>
    </div>
</template>